import gql from "graphql-tag";
/* Graphql mutations: */

export const UPSERT_USER = gql`
    mutation upsertCompanyUser($user: CompanyUserInput!, $removeTopicIdFromShop: [String]!) {
        upsertCompanyUser(user: $user, removeTopicIdFromShop: $removeTopicIdFromShop) {
            createdAt
            id
            email
            firstName
            lastName
            phoneNr
            roles
            shopIds
            passwordIsSet
            umbrellaCompanyIds
        }
    }
`;

export const DELETE_USER = gql`
    mutation deleteCompanyUser($companyUserId: String!) {
        deleteCompanyUser(companyUserId: $companyUserId)
    }
`;

export const UPSERT_UMBRELLA_ADMIN_USER = gql`
    mutation upsertUmbrellaAdminUser($companyUserId: String!, $umbrellaCompanyId: String!) {
        upsertUmbrellaAdminUser(companyUserId: $companyUserId, umbrellaCompanyId: $umbrellaCompanyId) {
            createdAt
            id
            email
            firstName
            lastName
            phoneNr
            roles
            shopIds
            passwordIsSet
            umbrellaCompanyIds
        }
    }
`;

export const COPY_USER_PROFILES_TO_COMPANY = gql`
    mutation copyUserOrderProfilesFromCompanyAndShopToCompanyAndShop(
        $fromCompanyId: String!
        $fromShopId: String!
        $toCompanyId: String!
        $toShopId: String!
    ) {
        copyUserOrderProfilesFromCompanyAndShopToCompanyAndShop(
            fromCompanyId: $fromCompanyId
            fromShopId: $fromShopId
            toCompanyId: $toCompanyId
            toShopId: $toShopId
        )
    }
`;
