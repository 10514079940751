import gql from "graphql-tag";

export const SAVE_NEW_QOPLA_PROMOTION = gql`
    mutation upsertQoplaPromotion($qoplaPromotion: QoplaPromotionInput!) {
        upsertQoplaPromotion(qoplaPromotion: $qoplaPromotion)
    }
`;

export const DELETE_QOPLA_PROMOTION = gql`
    mutation deleteQoplaPromotion($qoplaPromotionId: String!) {
        deleteQoplaPromotion(qoplaPromotionId: $qoplaPromotionId)
    }
`;

export const SAVE_PROMOTION_CONTACT_INFORMATION = gql`
    mutation savePromotionContactInformation($promotionContactLead: PromotionContactLeadInput!) {
        savePromotionContactInformation(promotionContactLead: $promotionContactLead)
    }
`;
